<template>
  <div class="corporate-action-row">
    <td>
      <div class="common-height">
        {{ entity.sgaId }}
      </div>
    </td>
    <td>
      <div class="common-height">{{ entity.requestId }}</div>
    </td>
    <td>
      <div class="common-height">{{ entity.legalEntityName }}</div>
    </td>
    <td>
      <div class="common-height">
        {{ entity.countryDOMName }}
      </div>
    </td>
    <td>
      <div class="common-height center-column-data">
        <b-radio
          v-model="markedAsMasterEntity"
          name="name"
          :native-value="entity.sgaId"
          @input="onRadioInput"
          :disabled="!entity.isSubscribed"
        >
        </b-radio>
      </div>
    </td>
  </div>
</template>

<script>
export default {
  name: 'CorporateActionItemRow',
  components: {},
  props: ['entity', 'masterEntity'],
  data() {
    return { markedAsMasterEntity: null }
  },
  methods: {
    onRadioInput() {
      this.$emit('markAsMaster', this.entity.sgaId)
    }
  }
}
</script>

<style lang="scss" scoped>
.corporate-action-row {
  width: 100%;
  display: contents;
  font-size: 13px;
  font-weight: 600;

  td {
    text-align: left;
    padding: 8px;
    font-family: Quicksand;
    color: var(--secondary-text-color);

    .common-height {
      height: 50px;
      display: flex;
      align-items: center;
    }

    .center-column-data {
      justify-content: center;
    }
  }
}
</style>
